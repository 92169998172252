body {
  background: #f3f6f6;
}

.page-breadcrumb,
.page-header:not(.panel) {
  background: transparent;
}

.page-breadcrumb,
.page-header,
hr,
.nav-tabs,
.nav-tabs.nav-justified {
  border-color: #e1e6e6;
}

.panel .nav-tabs {
  border-color: rgba(0, 0, 0, 0.11);
}

.panel,
.widget-more-link.b-a-1,
.widget-products-image,
*:not(.panel) .thumbnail {
  border-color: #dae0e0;
}

.bg-primary {
  background-color: #30add7 !important;
  border-color: #2a8eaf !important;
}

.bg-primary[href]:hover {
  background-color: #30a2c8 !important;
}

.bg-primary .bg-primary {
  border-color: #2a8eaf !important;
}

.bg-primary.darken,
.bg-primary .bg-primary.darken {
  background-color: #30a2c8 !important;
  border-color: #2784a3 !important;
}

.bg-primary.darken[href]:hover,
.bg-primary .bg-primary.darken[href]:hover {
  background-color: #2d98bc !important;
}

.bg-primary.darker,
.bg-primary .bg-primary.darker {
  background-color: #2d98bc !important;
  border-color: #257d9b !important;
}

.bg-primary.darker[href]:hover,
.bg-primary .bg-primary.darker[href]:hover {
  background-color: #2a8eaf !important;
}

.bg-primary, .bg-primary:hover, .bg-primary:focus, .bg-primary:active, .bg-primary[href],
.bg-primary a {
  color: white !important;
}

.bg-primary[href]:hover, .bg-primary[href]:focus, .bg-primary[href]:active,
.bg-primary a:hover,
.bg-primary a:focus,
.bg-primary a:active {
  color: #e6e6e6 !important;
}

.bg-primary[href]:hover, .bg-primary[href]:focus, .bg-primary[href]:active,
.bg-primary a:hover,
.bg-primary a:focus,
.bg-primary a:active {
  color: #fff !important;
}

.bg-primary .text-muted,
.bg-primary .text-muted:hover,
.bg-primary .text-muted:focus,
.bg-primary .text-muted:active,
.bg-primary .text-muted[href],
.bg-primary .text-muted a {
  color: rgba(255, 255, 255, 0.6) !important;
}

.text-primary, .text-primary:hover, .text-primary:focus, .text-primary:active, .text-primary[href],
.text-primary a {
  color: #228eb2 !important;
}

.text-primary[href]:hover, .text-primary[href]:focus, .text-primary[href]:active,
.text-primary a:hover,
.text-primary a:focus,
.text-primary a:active {
  color: #1a6c87 !important;
}

.border-primary {
  border-color: #30add7 !important;
}

.table-primary .table,
.table-primary.table {
  margin: 0;
  border-top-color: #307f9a !important;
}

.table-primary {
  margin-bottom: 21px;
}

.table-primary thead,
.table-primary thead tr,
.table-primary thead th,
.table-primary .table-header {
  border-color: #307f9a !important;
  color: #fff;
}

.table-primary thead tr,
.table-primary thead th {
  background: #2f97b9;
}

.table-primary .table-header {
  background: #30add7;
}

.table-primary .table-header .form-control {
  border: none;
}

.form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.has-error .form-control:focus {
  border-color: #30add7;
}

.btn-primary {
  color: #fff;
  border-color: #269bc3;
  background: #30add7;
}

.btn-primary.btn-outline {
  color: #2494ba;
  border-color: #269bc3;
  background: none;
}

.btn-primary:hover, .btn-primary:focus, .btn-primary.focus,
.btn-primary.btn-outline:hover,
.btn-primary.btn-outline:focus,
.btn-primary.btn-outline.focus {
  color: #fff;
  border-color: #2494ba;
  border-bottom-color: #15576d;
  background: #28a5d0;
}

.btn-primary:active,
.btn-primary.active,
.open > .btn-primary.dropdown-toggle,
.btn-primary.btn-outline:active,
.btn-primary.btn-outline.active,
.open > .btn-primary.dropdown-toggle.btn-outline {
  color: #fff;
  border-color: #1d7998;
  background: #269bc3;
}

.btn-primary:active:hover, .btn-primary:active:focus, .btn-primary:active.focus,
.btn-primary.active:hover,
.btn-primary.active:focus,
.btn-primary.active.focus,
.open > .btn-primary.dropdown-toggle:hover,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle.focus,
.btn-primary.btn-outline:active:hover,
.btn-primary.btn-outline:active:focus,
.btn-primary.btn-outline:active.focus,
.btn-primary.btn-outline.active:hover,
.btn-primary.btn-outline.active:focus,
.btn-primary.btn-outline.active.focus,
.open > .btn-primary.dropdown-toggle.btn-outline:hover,
.open > .btn-primary.dropdown-toggle.btn-outline:focus,
.open > .btn-primary.dropdown-toggle.btn-outline.focus {
  border-color: #1d7998;
  background: #2494ba;
}

.btn-primary.btn-3d:hover, .btn-primary.btn-3d:focus, .btn-primary.btn-3d.focus,
.btn-primary.btn-3d.btn-outline:hover,
.btn-primary.btn-3d.btn-outline:focus,
.btn-primary.btn-3d.btn-outline.focus {
  border-bottom-color: #1d7998;
}

.btn-primary.btn-3d:active, .btn-primary.btn-3d:active:hover, .btn-primary.btn-3d:active:focus, .btn-primary.btn-3d:active.focus,
.btn-primary.btn-3d.active,
.btn-primary.btn-3d.active:hover,
.btn-primary.btn-3d.active:focus,
.btn-primary.btn-3d.active.focus,
.open > .btn-primary.dropdown-toggle.btn-3d,
.open > .btn-primary.dropdown-toggle.btn-3d:hover,
.open > .btn-primary.dropdown-toggle.btn-3d:focus,
.open > .btn-primary.dropdown-toggle.btn-3d.focus {
  background: #28a5d0;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary {
  border-color: #269bc3 !important;
  background: #30add7 !important;
}

.btn-primary.btn-outline.disabled,
.btn-primary.btn-outline[disabled],
fieldset[disabled] .btn-primary.btn-outline {
  color: #2494ba;
  border-color: #269bc3 !important;
  background: none !important;
}

.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
  color: #fff !important;
  border-color: #1d7998 !important;
  background: #269bc3 !important;
}

.px-navbar {
  color: #444;
  box-shadow: 0 2px 2px rgba(0, 0, 0, .04), 0 1px 0 rgba(0, 0, 0, .04);;
}

.px-navbar .navbar-nav a,
.px-navbar .dropdown-menu a {
  color: #444;
}

.px-navbar .navbar-nav a:hover, .px-navbar .navbar-nav a:focus, .px-navbar .navbar-nav a:active,
.px-navbar .dropdown-menu a:hover,
.px-navbar .dropdown-menu a:focus,
.px-navbar .dropdown-menu a:active {
  color: #444;
  background: rgba(0, 0, 0, 0.04);
}

.px-navbar li.active > a,
.px-navbar li.active > a:hover,
.px-navbar li.active > a:focus,
.px-navbar li.active > a:active,
.px-navbar .dropdown.open > a,
.px-navbar .dropdown.open > a:hover,
.px-navbar .dropdown.open > a:focus,
.px-navbar .dropdown.open > a:active,
.px-navbar .dropdown.active > a,
.px-navbar .dropdown.active > a:hover,
.px-navbar .dropdown.active > a:focus,
.px-navbar .dropdown.active > a:active {
  color: #444;
  background: rgba(0, 0, 0, 0.04);
}

.px-navbar .navbar-nav li:hover,
.px-navbar .dropdown-menu li:hover {
  background: none;
}

.px-navbar .navbar-brand {
  background: none !important;
}

.px-navbar form.navbar-form .form-control {
  color: #444;
  border-color: #ddd !important;
  background: #fff;
}

.px-navbar .navbar-toggle {
  color: #444;
}

.px-navbar .navbar-toggle .navbar-toggle-icon,
.px-navbar .navbar-toggle .navbar-toggle-icon:before,
.px-navbar .navbar-toggle .navbar-toggle-icon:after {
  opacity: 1;
  color: #444;
  background: #444;
}

.px-navbar .navbar-toggle.collapsed {
  color: #444;
}

.px-navbar .navbar-toggle.collapsed .navbar-toggle-icon,
.px-navbar .navbar-toggle.collapsed .navbar-toggle-icon:before,
.px-navbar .navbar-toggle.collapsed .navbar-toggle-icon:after {
  color: #444;
  background: #444;
}

.px-navbar .ps-scrollbar-x-rail,
.px-navbar .ps-scrollbar-y-rail {
  background-color: transparent !important;
}

.px-navbar .ps-scrollbar-x-rail > .ps-scrollbar-x,
.px-navbar .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(0, 0, 0, 0.2) !important;
}

.px-navbar .navbar-header {
  color: #444;
  background: #fff;
}

.px-navbar .navbar-header a {
  color: #444;
}

.px-navbar .navbar-header a:hover, .px-navbar .navbar-header a:focus {
  color: #444;
  background: rgba(0, 0, 0, 0.04);
}

.px-navbar .dropdown-menu > li.divider {
  background: #f1f1f1;
}

@media (max-width: 767px) {
  .px-navbar .navbar-collapse {
    border-color: #f1f1f1;
    background: #fff;
  }
  .px-navbar .navbar-nav li,
  .px-navbar .dropdown-menu li {
    border-color: #f1f1f1;
  }
  .px-navbar .dropdown.open {
    background: #f6f6f6 !important;
    border-color: #e7e7e7;
  }
  .px-navbar .dropdown.open li {
    border-color: #e7e7e7;
  }
}

@media (min-width: 768px) {
  .px-navbar .navbar-collapse {
    border-color: transparent;
  }
  .px-navbar, .px-navbar .navbar-collapse {
    background: #fff;
  }
  .px-navbar .navbar-nav > li {
    border-color: transparent;
  }
  .px-navbar .dropdown-menu,
  .px-navbar .dropdown-multi-column {
    background: #fff;
  }
  .px-navbar .dropdown-menu .dropdown-toggle:hover > a {
    color: #444;
    background: rgba(0, 0, 0, 0.04);
  }
  .px-navbar [class^="widget-"],
  .px-navbar [class*=" widget-"] {
    border-color: #f1f1f1;
  }
  .px-navbar [class^="widget-"] a,
  .px-navbar [class^="widget-"] a:hover,
  .px-navbar [class^="widget-"] a:focus,
  .px-navbar [class*=" widget-"] a,
  .px-navbar [class*=" widget-"] a:hover,
  .px-navbar [class*=" widget-"] a:focus {
    background: transparent;
  }
  .px-navbar .navbar-header a,
  .px-navbar .navbar-nav > li > a {
    transition: all .3s;
  }
}

@media (min-width: 992px) {
  .px-nav-left:not(.px-nav-collapse) ~ .px-navbar .navbar-header {
    color: #fff;
    background: #30add7;
    margin-right: 1px;
    box-shadow: 1px 0 0 0 #30add7;
  }
  .px-nav-left:not(.px-nav-collapse) ~ .px-navbar .navbar-header a {
    color: #fff;
  }
  .px-nav-left:not(.px-nav-collapse) ~ .px-navbar .navbar-header a:hover, .px-nav-left:not(.px-nav-collapse) ~ .px-navbar .navbar-header a:focus {
    color: #fff;
  }
}

@media (min-width: 992px) {
  .px-nav-toggle {
    box-shadow: 0 2px 2px rgba(0, 0, 0, .04), 0 1px 0 rgba(0, 0, 0, .04);;
  }
}

.px-nav {
  color: #a4acba;
  background: #2a2d33;
}

.px-nav .ps-scrollbar-x-rail,
.px-nav .ps-scrollbar-y-rail {
  background-color: transparent !important;
}

.px-nav .ps-scrollbar-x-rail > .ps-scrollbar-x,
.px-nav .ps-scrollbar-y-rail > .ps-scrollbar-y {
  background-color: rgba(255, 255, 255, 0.3) !important;
}

.px-nav-dimmer {
  background: rgba(42, 45, 51, 0.4);
}

.px-nav-item a {
  color: #a4acba;
}

.px-nav-item a:hover, .px-nav-item a:focus, .px-nav-item a:active {
  color: #fff;
  background: transparent;
}

.px-nav-item.active > a {
  color: #fff !important;
  background: #1f2227;
}

.px-nav-item.active > a .px-nav-icon {
  color: #30add7;
}

.px-nav-dropdown.active > a .px-nav-icon {
  color: #30add7;
}

.px-nav-dropdown.active > a {
  background: none;
}

.px-nav-dropdown.active > a:hover, .px-nav-dropdown.active > a:focus, .px-nav-dropdown.active > a:active {
  background: transparent;
}

.px-nav-tooltip {
  background: #1f2227;
  color: #fff;
}

.px-nav-left .px-nav-tooltip:before {
  border-right-color: #1f2227;
}

.px-nav-right .px-nav-tooltip:before {
  border-left-color: #1f2227;
}

.px-show > a,
.px-nav-dropdown-menu-title {
  background: #30add7 !important;
  color: #fff !important;
}

.px-show > a .px-nav-icon,
.px-nav-dropdown-menu-title .px-nav-icon {
  color: #fff !important;
}

.px-nav-dropdown-menu {
  background: #34383f;
}

.px-nav-toggle {
  color: #fff;
  background: #4abfe6;
}

.px-nav-toggle .navbar-toggle-icon,
.px-nav-toggle .navbar-toggle-icon:before,
.px-nav-toggle .navbar-toggle-icon:after {
  color: #fff;
  background: #fff;
}

.px-nav-box {
  border-color: #393d45;
  color: #fff;
}

.px-nav-box .close {
  color: #a4acba;
}

.px-nav-box .close:hover, .px-nav-box .close:focus, .px-nav-box .close:active {
  color: #fff;
}

.px-nav-box .btn-outline {
  border-color: #393d45;
  color: #a4acba;
}

.px-nav-static .px-nav-dropdown > a,
.px-nav-static .px-nav-dropdown > a:hover,
.px-nav-static .px-nav-dropdown > a:focus {
  color: #a4acba;
}

@media (max-width: 991px) {
  .px-nav.px-nav-expand .px-open > a,
  .px-nav-dropdown-menu .px-open > a {
    color: #fff;
  }
}

@media (min-width: 992px) {
  .px-nav:not(.px-nav-collapse) .px-open > a,
  .px-nav-dropdown-menu .px-open > a {
    color: #fff;
  }
}

.px-footer {
  background: #f8fbfb;
  color: #444;
  border-color: #e5eaea;
}

.px-footer a {
  color: #666;
}

.px-footer a:hover,
.px-footer a:focus {
  color: #444;
}

.px-footer hr {
  border-color: #e5eaea;
}

.px-footer .text-muted {
  color: #888 !important;
}

.label-primary,
.badge-primary,
.panel-warning > .panel-heading .badge-primary {
  border-color: #30add7;
  background: #30add7;
  color: #fff;
}

.label-primary[href]:hover, .label-primary[href]:focus,
.badge-primary[href]:hover,
.badge-primary[href]:focus,
.panel-warning > .panel-heading .badge-primary[href]:hover,
.panel-warning > .panel-heading .badge-primary[href]:focus {
  border-color: #27a2cb;
  background: #27a2cb;
  color: #fff;
}

.label-primary.label-outline,
.badge-primary.label-outline,
.panel-warning > .panel-heading .badge-primary.label-outline {
  color: #228eb2;
  box-shadow: 0 0 0 1px #30add7 inset;
}

.label-primary.label-outline[href]:hover, .label-primary.label-outline[href]:focus,
.badge-primary.label-outline[href]:hover,
.badge-primary.label-outline[href]:focus,
.panel-warning > .panel-heading .badge-primary.label-outline[href]:hover,
.panel-warning > .panel-heading .badge-primary.label-outline[href]:focus {
  color: #1a6c87;
}

.label-primary.label-ribbon:before,
.badge-primary.label-ribbon:before,
.panel-warning > .panel-heading .badge-primary.label-ribbon:before {
  border-top-color: #3286a2;
}

.progress-bar {
  background-color: #30add7;
}

.dropdown-menu > .active > a, .dropdown-menu > .active > a:hover, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:active {
  color: #fff;
  background-color: #30add7;
}

.panel-primary {
  border-color: #269bc3 !important;
}

.panel-primary .panel-heading,
.panel-primary .panel-heading .accordion-toggle {
  background: #30add7;
  border-color: #269bc3;
  color: #fff;
}

.panel-primary .panel-heading-text a,
.panel-primary.panel-body-colorful a {
  color: #fff;
}

.panel-primary .panel-heading-text a:hover, .panel-primary .panel-heading-text a:focus,
.panel-primary.panel-body-colorful a:hover,
.panel-primary.panel-body-colorful a:focus {
  color: white;
}

.panel-primary .panel-heading-text a,
.panel-primary.panel-body-colorful .panel-body a,
.panel-primary.panel-body-colorful .panel-footer a {
  text-decoration: underline;
}

.panel-primary .panel-footer {
  border-color: #269bc3;
}

.panel-primary.panel-body-colorful,
.panel-primary.panel-body-colorful .panel-body,
.panel-primary.panel-body-colorful .panel-footer {
  background: #30add7;
  color: #fff;
}

.panel-primary.panel-body-colorful hr,
.panel-primary.panel-body-colorful .panel-body {
  border-color: #269bc3 !important;
}

.panel-primary.panel-dark {
  border-color: #30add7 !important;
}

.panel-primary.panel-dark .panel-heading,
.panel-primary.panel-dark .panel-heading .accordion-toggle {
  background: #30add7;
  border-color: #30add7;
  color: #fff;
}

.panel-primary.panel-dark .panel-heading-text a,
.panel-primary.panel-dark.panel-body-colorful a {
  color: #fff;
}

.panel-primary.panel-dark .panel-heading-text a:hover, .panel-primary.panel-dark .panel-heading-text a:focus,
.panel-primary.panel-dark.panel-body-colorful a:hover,
.panel-primary.panel-dark.panel-body-colorful a:focus {
  color: white;
}

.panel-primary.panel-dark .panel-heading-text a,
.panel-primary.panel-dark.panel-body-colorful .panel-body a,
.panel-primary.panel-dark.panel-body-colorful .panel-footer a {
  text-decoration: underline;
}

.panel-primary.panel-dark .panel-footer {
  border-color: #30add7;
}

.panel-primary.panel-dark.panel-body-colorful,
.panel-primary.panel-dark.panel-body-colorful .panel-body,
.panel-primary.panel-dark.panel-body-colorful .panel-footer {
  background: #30add7;
  color: #fff;
}

.panel-primary.panel-dark.panel-body-colorful hr,
.panel-primary.panel-dark.panel-body-colorful .panel-body {
  border-color: #30add7 !important;
}

.panel-primary.panel-dark .panel-heading .pagination a, .panel-primary.panel-dark .panel-heading .pagination a:hover, .panel-primary.panel-dark .panel-heading .pagination a:focus,
.panel-primary.panel-dark .panel-heading .pagination span,
.panel-primary.panel-dark .panel-heading .pagination span:hover,
.panel-primary.panel-dark .panel-heading .pagination span:focus,
.panel-primary.panel-dark .panel-heading .pager a,
.panel-primary.panel-dark .panel-heading .pager a:hover,
.panel-primary.panel-dark .panel-heading .pager a:focus,
.panel-primary.panel-dark .panel-heading .pager span,
.panel-primary.panel-dark .panel-heading .pager span:hover,
.panel-primary.panel-dark .panel-heading .pager span:focus {
  color: #fff;
}

.panel-primary.panel-dark .panel-heading .pagination li.active a, .panel-primary.panel-dark .panel-heading .pagination li.active a:hover, .panel-primary.panel-dark .panel-heading .pagination li.active a:focus,
.panel-primary.panel-dark .panel-heading .pagination li.active span,
.panel-primary.panel-dark .panel-heading .pagination li.active span:hover,
.panel-primary.panel-dark .panel-heading .pagination li.active span:focus,
.panel-primary.panel-dark .panel-heading .pager li.active a,
.panel-primary.panel-dark .panel-heading .pager li.active a:hover,
.panel-primary.panel-dark .panel-heading .pager li.active a:focus,
.panel-primary.panel-dark .panel-heading .pager li.active span,
.panel-primary.panel-dark .panel-heading .pager li.active span:hover,
.panel-primary.panel-dark .panel-heading .pager li.active span:focus {
  color: #30add7;
}

.panel-primary.panel-dark .panel-heading .nav-tabs > li > a {
  color: rgba(255, 255, 255, 0.9);
}

.panel-primary.panel-dark.panel-body-colorful .text-muted {
  color: rgba(255, 255, 255, 0.7) !important;
}

.panel-primary.panel-dark.panel-body-colorful .panel-heading,
.panel-primary.panel-dark.panel-body-colorful .panel-footer,
.panel-primary.panel-dark.panel-body-colorful .panel-body,
.panel-primary.panel-dark.panel-body-colorful hr,
.panel-primary.panel-dark.panel-body-colorful table,
.panel-primary.panel-dark.panel-body-colorful table tr,
.panel-primary.panel-dark.panel-body-colorful table th,
.panel-primary.panel-dark.panel-body-colorful table td,
.panel-primary.panel-dark.panel-body-colorful .list-group-item {
  border-color: #70c6e3 !important;
}

.custom-control:hover .custom-control-indicator {
  box-shadow: 0 0 0 1px #30add7 inset;
}

.custom-control:active .custom-control-indicator,
.custom-control:active .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #5bbedf;
  box-shadow: none;
}

.custom-control-input:checked ~ .custom-control-indicator {
  background-color: #30add7;
  box-shadow: none;
}

.custom-control-input:focus ~ .custom-control-indicator {
  box-shadow: 0 0 0 1px #30add7 inset, 0 0 0 1px #fff, 0 0 0 2px #30add7;
}

.custom-control-input:active ~ .custom-control-indicator {
  background-color: #5bbedf;
  box-shadow: none;
}

.custom-control-input:checked:disabled ~ .custom-control-indicator,
fieldset[disabled] .custom-control-input:checked ~ .custom-control-indicator {
  background-color: #30add7 !important;
  box-shadow: none !important;
}

.custom-file-input:focus ~ .custom-file-control {
  border-color: #30add7;
}

.custom-file-control:hover,
.custom-file-control:focus,
.custom-file-control:active {
  border-color: #30add7;
}

.tooltip-primary .tooltip-inner {
  background-color: #30add7;
  color: #fff;
}

.tooltip-primary.top .tooltip-arrow,
.tooltip-primary.top-left .tooltip-arrow,
.tooltip-primary.top-right .tooltip-arrow {
  border-top-color: #30add7;
}

.tooltip-primary.bottom .tooltip-arrow,
.tooltip-primary.bottom-left .tooltip-arrow,
.tooltip-primary.bottom-right .tooltip-arrow {
  border-bottom-color: #30add7;
}

.tooltip-primary.right .tooltip-arrow {
  /*@noflip*/
  border-right-color: #30add7;
}

.tooltip-primary.left .tooltip-arrow {
  /*@noflip*/
  border-left-color: #30add7;
}

.popover-primary {
  border-width: 1px;
}

.popover-primary.top .arrow {
  /*@noflip*/
  bottom: -9px;
}

.popover-primary.bottom .arrow {
  /*@noflip*/
  top: -9px;
}

.popover-primary.left .arrow {
  /*@noflip*/
  right: -9px;
}

.popover-primary.right .arrow {
  /*@noflip*/
  left: -9px;
}

.popover-primary .popover-title, .popover-primary.popover-colorful {
  color: #fff;
  background: #30add7;
}

.popover-primary,
.popover-primary .popover-title, .popover-primary.popover-colorful {
  border-color: #269bc3;
}

.popover-primary.popover-dark .popover-title, .popover-primary.popover-dark.popover-colorful {
  color: #fff;
  background: #30add7;
}

.popover-primary.popover-dark,
.popover-primary.popover-dark .popover-title, .popover-primary.popover-dark.popover-colorful {
  border-color: #30add7;
}

.popover-primary.popover-dark.popover-colorful .popover-title {
  border-color: #52bbde;
}

.nav li.active > a, .nav li.active > a:hover, .nav li.active > a:focus {
  color: #fff;
  background: #30add7;
}

.nav.nav-tabs > li.active > a {
  border-bottom-color: #269fc7 !important;
}

.pagination > li > a:hover,
.pagination > li > a:focus,
.pagination > li > a:active {
  border-color: #30add7;
}

.pagination > li.active > a,
.pagination > li.active > a:hover,
.pagination > li.active > a:focus,
.pagination > li.active > a:active,
.pagination > li.active > span {
  color: #fff;
  border-color: #30add7;
  background: #30add7;
}

.pager li > a:hover, .pager li > a:focus, .pager li > a:active {
  border-color: #30add7;
}

a.thumbnail:hover,
a.thumbnail:focus,
a.thumbnail.active,
a:hover .thumbnail,
a:focus .thumbnail,
a.active .thumbnail {
  border-color: #30add7 !important;
}

.list-group .list-group-item.active {
  color: #fff;
  border-color: #30add7;
  background: #30add7;
}

.list-group .list-group-item.active .list-group-item-text,
.list-group .list-group-item.active .list-group-icon {
  color: #fff;
}

.switcher-primary > input:checked ~ .switcher-indicator {
  color: #fff;
  background: #30add7;
}

.switcher-primary > input:focus ~ .switcher-indicator:after,
.switcher-primary > input:active ~ .switcher-indicator:after,
.switcher-primary:active .switcher-indicator:after {
  box-shadow: 0 0 0 1px #30add7 inset;
}

.select2-primary .select2-selection__choice,
.select2-primary + .select2-container .select2-selection__choice {
  background-color: #30add7;
}

.select2-results__option--highlighted[aria-selected] {
  color: #fff;
  background: #30add7;
}

.select2-container--focus .select2-selection,
.select2-container--focus .select2-dropdown,
.select2-container--open .select2-selection,
.select2-container--open .select2-dropdown {
  border-color: #30add7;
}

.ui-select-primary.ui-select-multiple .ui-select-match-item {
  background: #30add7 !important;
}

.ui-select-primary.ui-select-multiple .ui-select-match-item.btn-primary {
  background: #228eb2 !important;
}

.btn-default-focus .ui-select-toggle,
.ui-select-multiple.open,
.ui-select-container.open .ui-select-toggle,
.ui-select-container.open .ui-select-search,
.ui-select-choices,
.ui-select-no-choice {
  border-color: #30add7 !important;
}

.ui-select-choices-row.active > span {
  color: #fff;
  background-color: #30add7;
}

.datepicker thead th {
  color: #fff;
}

.datepicker .datepicker-switch,
.datepicker .prev,
.datepicker .next {
  background: #30add7;
}

.datepicker .datepicker-switch:hover,
.datepicker .prev:hover,
.datepicker .next:hover {
  background: #3db2d9;
}

.datepicker .dow,
.datepicker thead .cw {
  border-top: 1px solid #38839c;
  background: #3e93af;
}

.datepicker .active, .datepicker .active:hover,
.datepicker .range-start,
.datepicker .range-start:hover,
.datepicker .range-end,
.datepicker .range-end:hover {
  color: #fff !important;
  background: #30add7 !important;
}

.timepicker-increment:hover, .timepicker-increment:focus,
.timepicker-decrement:hover,
.timepicker-decrement:focus {
  border-color: #30add7;
}

.timepicker-increment:hover,
.timepicker-decrement:hover {
  color: #fff;
  background: #30add7;
}

.dz-upload-icon {
  background-color: #30add7;
}

.dropzone-box.dz-drag-hover {
  border-color: #30add7;
}

.px-irs-primary .irs-bar,
.px-irs-primary .irs-bar-edge,
.px-irs-primary .irs-slider:after {
  background: #30add7;
}

.px-irs-primary .irs-slider.state_hover:after,
.px-irs-primary .irs-slider:hover:after {
  background: #1a6c87;
}

.px-irs-primary .irs-from,
.px-irs-primary .irs-to,
.px-irs-primary .irs-single {
  color: #fff;
  background: #30add7;
}

.px-irs-primary .irs-from:after,
.px-irs-primary .irs-to:after,
.px-irs-primary .irs-single:after {
  border-top-color: #30add7;
}

.daterangepicker .calendar td.active,
.daterangepicker .calendar th,
.daterangepicker .ranges .active,
.daterangepicker .ranges li:hover,
.daterangepicker .ranges li:focus {
  background: #30add7;
  color: #fff;
}

.daterangepicker .calendar th {
  border-color: #30add7;
}

.daterangepicker select:focus {
  border-color: #30add7;
}

.daterangepicker .calendar th.available:hover {
  background: #3db2d9;
}

.daterangepicker .calendar thead tr + tr th {
  border-top-color: #38839c !important;
  background: #3e93af !important;
}

.bootstrap-tagsinput.focus {
  border-color: #30add7 !important;
}

tags-input .suggestion-item.selected {
  color: #fff;
  background: #30add7;
}

tags-input .tags.focused {
  border-color: #30add7 !important;
}

.slider-primary .slider-selection,
.slider-primary .slider-selection.tick-slider-selection,
.slider-primary .slider-tick.in-selection,
.slider-primary .slider-reversed .slider-track-high,
.slider-primary .slider-reversed .slider-tick {
  background: #30add7;
}

.slider-primary.slider-reversed .slider-track-high,
.slider-primary.slider-reversed .slider-tick {
  background: #30add7;
}

.noUi-primary .noUi-connect {
  background: #30add7;
}

.noUi-active:before,
.noUi-active:after {
  background: #30add7;
}

.owl-nav [class*='owl-']:hover {
  background: #30add7;
  color: #fff;
}

.owl-dots .owl-dot.active span,
.owl-dots .owl-dot:hover span {
  background: #30add7;
}

*:not(.panel) .widget-pricing .widget-pricing-item {
  border-color: #dae0e0;
}

*:not(.panel) .widget-pricing-simple .widget-pricing-item {
  border-color: rgba(218, 224, 224, 0);
}

*:not(.panel) .widget-pricing-simple .widget-pricing-active {
  border-color: #dae0e0;
}

*:not(.panel) .widget-pricing-simple.widget-pricing-hover:hover .widget-pricing-item {
  border-color: rgba(218, 224, 224, 0);
}

*:not(.panel) .widget-pricing-simple.widget-pricing-hover:hover .widget-pricing-item:hover {
  border-color: #dae0e0;
}

*:not(.panel) .widget-pricing-expanded .widget-pricing-item {
  border-color: #dae0e0;
}

*:not(.panel) .widget-pricing-expanded.widget-pricing-simple .widget-pricing-active,
*:not(.panel) .widget-pricing-expanded.widget-pricing-simple.widget-pricing-hover:hover .widget-pricing-item {
  border-color: #dae0e0;
}

.widget-timeline:before,
.widget-timeline:after {
  background: #e2e9e9;
}

.widget-timeline-section {
  color: #444;
  background: #e2e9e9;
}

.widget-timeline-bullet:before,
.widget-timeline-icon:before,
.widget-timeline-image:before {
  background: #e2e9e9;
}

.widget-timeline-bullet {
  background: #e2e9e9;
}

.widget-timeline-icon {
  background: #e2e9e9;
  color: #444;
}

.widget-timeline-time {
  color: #444;
  background: #e2e9e9;
}

.px-navbar .navbar-form .form-control {
  border-radius: 999px;
}

.px-navbar .dropdown-multi-column .dropdown-menu {
  border-color: #e5e5e5;
}

@media (min-width: 992px) {
  .px-nav-off-canvas.px-nav-collapse .px-nav-toggle {
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.04), 0 1px 0 rgba(0, 0, 0, 0.04);
  }
}

.pace-progress {
  background: #30add7;
}

.tab-content-fix .tab-content {
  padding: 0;
}

.label > user-name {
  float: left;
}

.widget-tree-comments-footer, .widget-tree-comments-footer a.active {
  color: #337ab7;
}

*:focus {
  outline: none !important;
}

.widget-support-tickets-item .label {
  margin-top: 2px;
  margin-right: 20px;
}

.tab-content-fix .tab-content {
  padding: 0;
}

.px-content {
  padding-top: 70px !important;
  padding-left: 20x;
  padding-right: 20px;
  padding-bottom: 80px;
}

@media (min-width: 544px) {
  .px-content {
    padding-left: 75px;
  }
}

@media (min-width: 992px) {
  .px-content {
    padding-left: 250px;
  }
}

.fullscreen .modal-dialog {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.fullscreen .modal-content {
  height: auto;
  min-height: 100%;
  border-radius: 0;
}

.page-signin-header {
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.page-signin-header .btn {
  position: absolute;
  top: 12px;
  right: 15px;
}

.page-signin-container {
  width: auto;
  margin: 30px 10px;
}

@media (min-width: 544px) {
  .page-signin-container {
    width: 350px;
    margin: 60px auto;
  }
}

.page-signin-container form {
  border: 0;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.05), 0 1px 0 rgba(0, 0, 0, 0.05);
}

.page-signin-social-btn {
  width: 40px;
  padding: 0;
  line-height: 40px;
  text-align: center;
  border: none !important;
}

#page-signin-forgot-form {
  display: none;
}

@media (max-width: 544px) {
  .px-navbar {
    position: fixed;
  }
}

@media (max-width: 992px) {
  .navbar-header {
    text-align: center;
  }
}

@media (max-width: 992px) {
  .navbar-header .navbar-brand {
    float: none;
  }
}

.widget-timeline-item {
  padding-left: 110px !important;
}

.widget-timeline-bullet:before,
.widget-timeline-icon:before,
.widget-timeline-image:before {
  right: -15px !important;
}

.search-field .form-control-feedback {
  top: 9px;
}

.search-field input[type='text'],
.search-field input[type='number'],
.search-field textarea {
  font-size: 16px;
}

.ui-select-container .btn {
  background: #fff;
}

.btn-default-focus {
  outline: none;
}

tr.user-list > td {
  line-height: 50px !important;
}

@media (min-width: 767px) {
  .has-user-image {
    padding-left: 80px !important;
    position: relative;
    height: 68px;
  }
}

.has-user-image img {
  display: none;
}

@media (min-width: 767px) {
  .has-user-image img {
    display: block;
    max-width: 50px;
    position: absolute;
    left: 14px;
  }
}

.page-profile-v2-header.page-header {
  border: none;
  margin-bottom: 0;
  border-radius: 0;
}

.page-profile-v2-header.page-header .box {
  background: none !important;
}

.page-profile-v2-subheader {
  background: rgba(0, 0, 0, 0.02);
}

.page-profile-v2-avatar {
  max-width: 80px;
  border: 4px solid #fff;
  position: relative;
}

@media (min-width: 768px) {
  .page-profile-v2-avatar {
    margin-top: -70px;
  }
}

.page-profile-v1-avatar {
  max-width: 160px;
  max-height: 160px;
}

.box__dragndrop,
.box__uploading,
.box__success,
.box__error {
  display: none;
}

.box.has-advanced-upload {
  background-color: white;
  outline: 2px dashed black;
  outline-offset: -10px;
}

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: grey;
}

.box.is-uploading .box__input {
  visibility: none;
}

.box.is-uploading .box__uploading {
  display: block;
}

.box__button {
  display: none;
}

.no-js .box__button {
  display: block;
}

.ticket-list .list-group-item {
  padding-top: 5px;
  padding-bottom: 5px;
}

.ng-leave {
  display: none !important;
}

.table td.text {
  max-width: 250px;
}

.table td.text .overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.form-loading {
  min-height: 200px;
}

.ticket-detail h5 {
  font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
}

.moment-picker {
  z-index: 5000 !important;
}

@media print {
  .px-nav-left {
    display: none;
  }
}

.service-ticket h1 .label {
  margin-top: 6px;
  margin-left: 4px;
  font-weight: bold;
}

td.delete {
  width: 30px !important;
}

.hackyhack {
  position: relative;
}

.hackyhack input {
  opacity: 0;
  width: 0;
  height: 0;
  padding: 0;
}

.hackyhack .dropdown-menu {
  left: auto !important;
  right: 0px;
  margin-top: 30px;
}

.uib-datepicker .btn {
  border-radius: 0;
}

.uib-datepicker > table tr > td:first-child,
.uib-datepicker > table tr > th:first-child {
  padding-left: 6px !important;
}

.uib-datepicker > table tr > td:last-child,
.uib-datepicker > table tr > th:last-child {
  padding-right: 6px !important;
}

project-list .widget-support-tickets-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

project-list .list-group,
project-list .list-group-item {
  background: none;
}

.m-h-100 {
  min-height: 100px;
}

.m-h-200 {
  min-height: 200px;
}

.projectNumber {
  line-height: 31px;
}

.projectNumber .edit {
  margin-left: 10px;
}

.completed {
  text-decoration: line-through;
}

#chart {
  max-height: 140px;
}

.bgcolor {
  background: #fafafa;
}

.pac-container {
  z-index: 99999 !important;
}

